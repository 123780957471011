import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import Heading from "@components/elements/heading"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import List from "@components/elements/list"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { graphql } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import Div from "@components/elements/div"

const Checkmark = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#18AED8" />
    <path
      d="M7.99998 12.78L5.21998 10L4.27332 10.94L7.99998 14.6667L16 6.66668L15.06 5.72668L7.99998 12.78Z"
      fill="white"
    />
  </svg>
)

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: .75rem;

  span {
    line-height: 1.5;
    margin-top: -0.2rem;
  }
`

const RequestDemoPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { color, shadow } = useTheme()

  return (
    <Layout
      title="Request a Demo"
      description={`Request a demo of Klipfolio. See how you can build dashboards for your team and clients.`}
      fullWidth
      background={color.blueLight}
      hideOverflow
    >
      <Container text>
        <Grid
          height="100%"
          margin="6rem 0 8rem"
          marginSm="2rem 0 4rem"
          gap="4rem"
          alignItems="center"
          columns="1fr 1fr"
          columnsMd="1fr"
        >
          <Flex gap="2rem">
            <KlipsLogo height="60px" width="129px" />
            <Heading as="h1" fontSize="2rem">
              Build custom dashboards for you and your team.
            </Heading>
            <List
              color={color.blue500}
              gap="1.2rem"
              fontSize="1rem"
              fontWeight="600"
              listStyle="none"
              margin="0"
            >
              <ListItem>
                <Checkmark />
                <span>Connect and refresh your data in one place.</span>
              </ListItem>
              <ListItem>
                <Checkmark />
                <span>
                  Use powerful, familiar functions and formulas to manage your
                  data.
                </span>
              </ListItem>
              <ListItem>
                <Checkmark />
                <span>
                  Visualize anything with 30+ chart types and custom layouts.
                </span>
              </ListItem>
              <ListItem>
                <Checkmark />
                <span>Share and distribute dashboards easily, anywhere.</span>
              </ListItem>
            </List>
            <Image file={images["01b338db-466e-4532-801c-ad31eaf71340"]} />
          </Flex>
          <Div
            boxShadow={shadow.glassy}
            background="white"
            padding="2rem 2rem 0"
            borderRadius="2rem"
          >
            <Heading as="h4" margin="0 0 1rem">See Klips in action</Heading>
            <Form>
              <HubspotForm
                portalId="7477725"
                formId="a0788cf8-8d01-41f2-9b12-4eee8cf54a5e"
              />
            </Form>
          </Div>
        </Grid>
      </Container>
    </Layout>
  )
}

RequestDemoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RequestDemoPage

export const pageQuery = graphql`
  query RequestDemoQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b6e018a3-2db1-43a6-a8fe-0d2c89e5c7fd"
            "01b338db-466e-4532-801c-ad31eaf71340"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
